import React from "react";
import { Layout, Row, Col } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import "./index.less";

const { Footer } = Layout;

const MainFooter: React.FC = () => {
  return (
    <Footer className="main-footer">
      <div className="footer-content">
        <Row className="footer-links">
          <Col className="footer-link-col" flex="auto">
            <Link to="/">
              <StaticImage
                loading="lazy"
                placeholder="none"
                imgStyle={{ objectFit: "contain" }}
                width={180}
                src="../../../images/logo.svg"
                alt="logo"
              ></StaticImage>
            </Link>
          </Col>
          <Col className="footer-link-col" flex="auto">
            <Link className="footer-link" to="/privacy">
              Privacy Policy
            </Link>
          </Col>
          <Col className="footer-link-col" flex="auto">
            <Link className="footer-link" to="/terms">
              Terms of Service
            </Link>
          </Col>
          <Col className="footer-link-col" flex="auto">
            <span className="footer-link">
              {"PWDigital LLC " +
                new Date().getFullYear() +
                " All Rights Reserved"}
            </span>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default MainFooter;
