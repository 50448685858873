import React from "react";
import { Layout } from "antd";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import Header from "./Header";
import Footer from "./Footer";

import "./index.less";

const { Content } = Layout;

const MainLayout: React.FC = (props) => {
  return (
    <Layout className="main-layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PWdigital</title>
        <link rel="canonical" href="https://pwdigital.io" />
        <meta
          name="description"
          content="PWDigital's platform is a personalized ad delivery system that creates engageable moments within videos for users."
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header></Header>
      <Content className="main-content">{props.children}</Content>
      <Footer></Footer>
    </Layout>
  );
};

export default MainLayout;
