import React from "react";
import { useMediaQuery } from "react-responsive";

import { StaticImage } from "gatsby-plugin-image";
import { PageHeader, Button } from "antd";
import { Link } from "gatsby";
import { MailOutlined } from "@ant-design/icons";

import "./index.less";

const MainHeader: React.FC = (props) => {
  const isVerySmall = useMediaQuery({ query: "(max-width: 395px)" });

  return (
    <PageHeader
      className="main-header"
      ghost={false}
      title={
        <Link to="/">
          <StaticImage
            placeholder="none"
            imgStyle={{ objectFit: "contain" }}
            width={135}
            loading="lazy"
            id="logo-img"
            className="logo verysmallscreen"
            src="../../../images/logo.svg"
            alt="logo"
          ></StaticImage>
          <StaticImage
            placeholder="none"
            imgStyle={{ objectFit: "contain" }}
            width={180}
            loading="lazy"
            id="logo-img"
            className="logo normalscreens"
            src="../../../images/logo.svg"
            alt="logo"
          ></StaticImage>
        </Link>
      }
      extra={[
        <Button className="header-link">
          <a
            target="_blank"
            href="https://s3.amazonaws.com/assets.pwdigital.io/PWDIGITAL+MEDIA+KIT+FIRST.pdf"
            download
          >
            Media Kit
          </a>
        </Button>,
        <Button className="header-link contact">
          <a target="_blank" href="mailto:sales@pwdigital.io">
            Contact
          </a>
        </Button>,
        <a target="_blank" className="header-link-flex" href="mailto:sales@pwdigital.io">
          <MailOutlined className="header-link-icon contact" />
        </a>,
      ]}
    ></PageHeader>
  );
};

export default MainHeader;
